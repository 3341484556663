import React from 'react'
import * as styles from '../constants/styles'

class ResourceItem extends React.Component {
  render () {
    const { index, resource } = this.props

    return (
      <tr className={`group hover:bg-coral ${styles.zebraRowClasses[index % 2]} p-4`}>
        <td className='p-4 max-w-xs w-1/5 min-w-20 font-bold group-hover:text-white'>{resource.node.name}</td>
        <td className='w-xs max-w-xs w-2/5 min-w-2/5 px-4 group-hover:text-white'>{resource.node.description && resource.node.description.description}</td>
        {/* @todo the same as the website below
      @todo add tel */}
        <td className='font-bold min-w-15 w-1/5 px-4'>
          {
            resource.node.phoneNumbers && resource.node.phoneNumbers.map((num, index) =>
              <a className='text-black group-hover:text-white' href={`tel:${num}`} key={num}>{num}{resource.node.phoneNumbers.length === index + 1 ? !resource.node.phoneNumbersSupportsSpanish && <span role='img' aria-label='Does not support Spanish'>&#128314;</span> : ''}</a>
            )
          }
        </td>
        <td className='text-coral hover:text-white w-1/5 min-w-20'>
          {/* if website exists */}
          {/* // @todo do we need an warning modal or page? */}
          {(resource.node.website &&
          (<a
            href={resource.node.website}
            target='_blank'
            rel='noopener noreferrer'
            className='group-hover:text-white break-all block w-full h-full px-4'
          >
            {
              resource.node.website
                .replace('http://www.', '')
                .replace('https://www.', '')
                .replace('http://', '')
            }
            {/* if website exists and not supports spanish */}
            {resource.node.website &&
            !resource.node.phoneNumbersSupportsSpanish &&
              <span role='img' aria-label='Does not support Spanish'>&#128314;</span>}
          </a>)
          )}

        </td>
      </tr>
    )
  }
}

export default ResourceItem
