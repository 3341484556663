import React from 'react'

class ResourceMobileItem extends React.Component {
  render () {
    const { resource } = this.props

    return (
      <tr className='group w-full'>
        <td className='w-full'>
          <div className='w-full flex flex-col mb-2 bg-gray hover:bg-coral'>
            <div className='w-full py-2 px-6 font-bold group-hover:text-white'>{resource.node.name}</div>
            <div className='w-xs w-full py-2 px-6 group-hover:text-white'>{resource.node.description && resource.node.description.description}</div>
            {/* @todo the same as the website below
          @todo add tel */}
            {
              resource.node.phoneNumbers && resource.node.phoneNumbers.map((num, index) =>
                <div key={index} className='flex items-center font-bold w-full py-2 px-6'><a className='text-black group-hover:text-white' href={`tel:${num}`} key={num}>{num}{resource.node.phoneNumbers.length === index + 1 ? !resource.node.phoneNumbersSupportsSpanish && <span role='img' aria-label='Does not support Spanish'>&#128314;</span> : ''}</a></div>
              )
            }
            <div className='flex items-center text-coral hover:text-white w-full group-hover:text-white'>
              {/* if website exists */}
              {/* // @todo do we need an warning modal or page? */}
              {(resource.node.website &&
              (<a
                href={resource.node.website}
                target='_blank'
                rel='noopener noreferrer'
                className='block py-2 px-6 hover:bg-coral group-hover:text-white break-all w-full'
              >
                {
                  resource.node.website
                    .replace('http://www.', '')
                    .replace('https://www.', '')
                    .replace('http://', '')
                }
                {/* if website exists and not supports spanish */}
                {resource.node.website &&
                !resource.node.phoneNumbersSupportsSpanish &&
                  <span role='img' aria-label='Does not support Spanish'>&#128314;</span>}
              </a>)
              )}
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

export default ResourceMobileItem
