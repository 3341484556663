import React from 'react'
import ResourcesGroup from '../components/resources-group'

class Resources extends React.Component {
  render () {
    const resourcesGrouped = this.props.resources.group

    return (
      <div>
        {resourcesGrouped.map((group, key) =>
          <ResourcesGroup name={group.fieldValue} resources={group.edges} key={key} index={key} />
        )}
      </div>
    )
  }
}

export default Resources
