import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Resources from '../components/resources'
import {
  documentToReactComponents,
  documentToReactComponentsOptions
} from '../components/contentful'
import pasteIcon from '../images/icon-paste.svg'

class ResourcesPage extends React.Component {
  render () {
    const page = this.props.data.contentfulPage
    const resources = this.props.data.allContentfulResources
    const document = page.pageContent.json

    let heroImageUrl = ''
    let heroAltText = ''
    let newDocument = {
      ...document
    }

    // @todo use .shift() here on the array
    if (document.content && document.content[0].nodeType === BLOCKS.EMBEDDED_ASSET) {
      heroImageUrl = document.content[0].data.target.fields ? document.content[0].data.target.fields.file['en-US'].url : ''
      // remove "hero" and replace "-" with a space
      heroAltText = document.content[0].data.target.fields ? document.content[0].data.target.fields.title['en-US'].replace(/hero/g, '').replace(/-/g, ' ') : ''

      newDocument = {
        ...document,
        content: document.content.filter((c, index) => index !== 0)
      }
    }

    const headContentElements = documentToReactComponents(
      {
        ...newDocument,
        content: newDocument.content.filter((c, index) => index < 2)
      },
      documentToReactComponentsOptions
    )

    const leftContentElements = documentToReactComponents(
      {
        ...newDocument,
        content: newDocument.content.filter((c, index) => index === 2)
      },
      documentToReactComponentsOptions
    )

    const rightContentElements = documentToReactComponents(
      {
        ...newDocument,
        content: newDocument.content.filter((c, index) => index > 2)
      },
      documentToReactComponentsOptions
    )

    return (
      <Layout
        location={this.props.location}
        heroUrl={heroImageUrl}
        heroAltText={heroAltText}
        locale={this.props.pageContext.locale}
      >
        <SEO title='Resources' />
        {/* // @todo turn into component and pass in icon, title */}
        <div className='main-content container px-5 lg:px-4 mx-auto top-0'>
          <h2 className='h-16 px-3 flex items-center text-2xl text-white font-bold bg-turqouise'>
            <img src={pasteIcon} alt='' className='inline-block w-10 h-10 mr-3 ie-object-contain' width='40' height='40' />
            <FormattedMessage id='link.resources' />
          </h2>
          <div className='mb-8'>
            {headContentElements}
            <div className='flex flex-col lg:flex-row'>
              <div className='w-full lg:w-1/2 lg:pr-16'>{leftContentElements}</div>
              <div className='w-full lg:w-1/2 lg:pr-24'>{rightContentElements}</div>
            </div>
          </div>
          <Resources resources={resources} />
        </div>
      </Layout>
    )
  }
}

export default ResourcesPage

export const resourcesPageQuery = graphql`
  query resourcesPageQuery($locale: String!) {
    contentfulPage(
      slug: { eq: "resources" },
      node_locale: { eq: $locale }
    ) {
      id
      title
      pageContent {
        json
      }
    }
    allContentfulResources(
      filter: {
        node_locale: { eq: $locale }
      }
    ) {
      group(field: category___name) {
        fieldValue
        field
        totalCount
        edges {
          node {
            id
            name
            description {
              id
              description
            }
            category {
              id
              name
              node_locale
            }
            phoneNumbers
            phoneNumbersSupportsSpanish
            website
            websiteSupportsSpanish
          }
        }
      }
      nodes {
        id
        name
        description {
          id
          description
        }
        category {
          id
          name
        }
        phoneNumbers
        phoneNumbersSupportsSpanish
        website
        websiteSupportsSpanish
        updatedAt
      }
    }
  }
`
