import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { FormattedMessage } from 'react-intl'
import ResourceItem from './resource-item'
import ResourceMobileItem from './resource-mobile-item'

class ResourcesGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      expands: [props.index]
    }
  }

  render () {
    const name = this.props.name
    const resources = this.props.resources.sort((r1, r2) => {
      if (r1.node.name > r2.node.name) {
        return 1
      } else if (r1.node.name === r2.node.name) {
        return 0
      } else {
        return -1
      }
    })
    const { expands } = this.state

    return (
      <div className='mb-5 lg:mb-10'>
        <Accordion className='border-0' allowZeroExpanded onChange={e => this.setState({ expands: e })} preExpanded={expands}>
          <AccordionItem uuid={this.props.index}>
            <AccordionItemHeading aria-level='2'>
              <AccordionItemButton className='bg-turqouise'>
                <div className={`h-16 px-3 flex justify-between items-center text-2xl text-white font-bold${expands.length !== 0 ? ' bg-turqouise' : ' bg-coral'}`}>
                  <h2 className='text-2xl text-white pl-2 pr-2'>{name}</h2>
                  <span>{expands.length === 0 ? '〉' : '﹀'}</span>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='px-0'>
              <table className='hidden lg:table border-separate table-fixed w-full leading-tight'>
                <tbody>
                  <tr className='hidden'>
                    <th><FormattedMessage id='table.cell.name' /></th>
                    <th><FormattedMessage id='table.cell.description' /></th>
                    <th><FormattedMessage id='table.cell.phone' /></th>
                    <th><FormattedMessage id='table.cell.website' /></th>
                  </tr>
                  {resources.map((resource, index) =>
                    <ResourceItem resource={resource} index={index} key={index} />
                  )}
                </tbody>
              </table>
              <table className='border-collapse table lg:hidden table-fixed -ml-0 lg:-ml-6 -mr-0 lg:-mr-6 -mt-1 lg:-mt-6 bg-gray-100 w-full'>
                <tbody style={{ borderSpacing: 0 }}>
                  {resources.map((resource, index) =>
                    <ResourceMobileItem resource={resource} index={index} key={index} />
                  )}
                </tbody>
              </table>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  }
}

export default ResourcesGroup
